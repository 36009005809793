import React from 'react';

const AboutMe = () => {
  return (
    <section id='AboutMe' className='about--section'>
      <div className='about--section--img'>
        <img src='./img/aula.png' alt='About Me' />
      </div>
      <div className='hero--section--content-box about--section--box'>
        <div className='hero--section--content'>
          {/* <p className='section--title'>About</p> */}
          <h1 className='skills--section--heading'>Sobre Mim</h1>
          <p className='hero--section-description'>
            Desde criança sempre tive curiosidade em descobrir como as coisas
            funcionam e interesse por tecnologia, o que me levou a estudar
            engenharia elétrica pela UFSJ.
          </p>
          <p className='hero--section-description'>
            Passei a atuar como educador em 2018 e ao criar um site para expor
            os projetos dos alunos me interessei pelo assunto e decidi
            aprofundar meus conhecimentos, assistindo tutoriais, cursos e
            assinando a plataforma Alura com o objetivo de ingressar na área de
            Front-End.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
