import React from 'react';

const HeroSection = () => {
  return (
    <section id='heroSection' className='hero--section'>
      <div className='hero--section--content--box'>
        <div className='hero--section--content'>
          <p className='section--title'>Thiago Miyaguchi</p>
          <h1 className='hero--section--title'>
            <span className='hero--section--title--color'>Full Stack</span>
            <br />
            Developer
          </h1>
          <p className='hero--section--description'>
            Engenheiro Eletricista - Universidade Federal de São João del Rei
            <br />
            Professor de Educação Tecnológica
            <br />
            Graduando em Desenvolvimento Web
          </p>
        </div>
        <a href='https://www.linkedin.com/in/thiagomiyaguchi/' target='blank'>
          <button className='btn btn-primary'>LinkedIn</button>
        </a>
      </div>
      <div className='hero--section--img'>
        <img src='./img/hero_img_thiago.png' alt='Hero Section' />
      </div>
    </section>
  );
};

export default HeroSection;
