import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu;
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu;
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? 'active' : ''}`}>
      <div>
        <img src='./img/logo.png' alt='Logo' />
      </div>
      <a
        className={`nav__hamburger ${navActive ? 'active' : ''}`}
        onClick={toggleNav}
      >
        <span className='nav__hamburger__line'></span>
        <span className='nav__hamburger__line'></span>
        <span className='nav__hamburger__line'></span>
      </a>
      <div className={`navbar--items ${navActive ? 'active' : ''}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass='navbar--active-content'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to='heroSection'
              className='navbar--content'
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass='navbar--active-content'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to='mySkills'
              className='navbar--content'
            >
              Habilidades
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass='navbar--active-content'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to='AboutMe'
              className='navbar--content'
            >
              Sobre Mim
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass='navbar--active-content'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to='MyPortfolio'
              className='navbar--content'
            >
              Portfólio
            </Link>
          </li>

          {/* <li>
            <Link
              onClick={closeMenu}
              activeClass='navbar--active-content'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to='Testimonials'
              className='navbar--content'
            >
              Testimonials
            </Link>
          </li> */}
        </ul>
      </div>

      <a href='https://www.linkedin.com/in/thiagomiyaguchi/' target='blank'>
        <button
          onClick={closeMenu}
          // offset={-70}
          // duration={500}
          // to='Contact'
          className='btn btn-outline-primary'
        >
          LinkedIn
        </button>
      </a>
    </nav>
  );
};

export default Navbar;
