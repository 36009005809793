import data from '../../data/index.json';
import React from 'react';

const MySkills = () => {
  return (
    <section className='skills--section' id='mySkills'>
      <div className='portfolio--container'>
        <p className='section--title'>Meus Interesses e Conhecimentos</p>
        <h2 className='skills--section--heading'>Habilidades</h2>
      </div>
      <div className='skills--section--container'>
        {data?.skills?.map((item) => (
          <div key={item.id} className='skills--section--card'>
            <div className='skills--section--img'>
              <img src={item.src} alt='Product Chain' />
            </div>
            <div className='skills--section--card--content'>
              <h3 className='skills--section--title'>{item.title}</h3>
              <p className='skills--section--description'>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MySkills;
