import HeroSection from '../HeroSection';

import React from 'react';
import MySkills from '../MySkills';
import AboutMe from '../AboutMe';
import MyPorfolio from '../MyPorfolio';
import Testimonials from '../Testimonials';
import ContactMe from '../ContactMe';
import Footer from '../Footer';

const Home = () => {
  return (
    <>
      <HeroSection />
      <MySkills />
      <AboutMe />
      <MyPorfolio />
      {/* <Testimonials /> */}
      {/* <ContactMe /> */}
      <Footer />
    </>
  );
};

export default Home;
